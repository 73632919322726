// 业务痛点
.casePain {
    width: 1200px;
    padding: 80px 0px 88px 0;
    box-sizing: border-box;
    margin: 0 auto;
    .title {
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0a1e2d;
        text-align: center;
        margin-bottom: 64px;
    }
    .contentBox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .box{
             padding: 0px 45px 30px;
             margin-right: 30px;
             .imgBox {
                width: 72px;
                height: 66px;
                // background: linear-gradient(4deg, #1096fe, #a2f0fd);
                border-radius: 10px;
                margin: 48px auto 26px;
                img {
                    width: 100%;
                    height: 100%;
                    // margin-top: 18px;
                }
            }
        }
        .box,.box2 {
            flex: 1;
            height: 400px;
            // width: 381px;
            background: #f2f5fa;
            border-radius: 6px;
            text-align: center;
            box-sizing: border-box;
            box-shadow: 0px 2px 18px 0px rgba(107,138,162,0.1);
           
            .tit {
                font-size: 21px;
                line-height: 21px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #0a1e2d;
                margin-bottom: 16px;
            }
            .cont {
                font-size: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #434343;
                line-height: 24px;
                text-align: justify;
            }
        }
        .box:nth-last-child(1) {
            margin-right: 0px;
            background-image: url('../../assets/Solution/3-bg.png');
        }
        .box:nth-of-type(1){
            background-image: url('../../assets/Solution/1-bg.png');
        }
        .box:nth-of-type(2){
            background-image: url('../../assets/Solution/2-bg.png');
        }
        .box2{
            padding: 0px 26px 30px;
            margin-right: 20px;
            .imgBox {
                width: 72px;
                height: 66px;
                // background: linear-gradient(4deg, #1096fe, #a2f0fd);
                border-radius: 10px;
                margin: 56px auto 40px;
                img {
                    width: 100%;
                    height: 100%;
                    // margin-top: 18px;
                }
            }
        }
       .box2:nth-last-child(1) {
            margin-right: 0px;
            background-image: url('../../assets/Solution/4-4.png');
        }
        .box2:nth-of-type(1){
            background-image: url('../../assets/Solution/4-1.png');
        }
        .box2:nth-of-type(2){
            background-image: url('../../assets/Solution/4-2.png');
        }
        .box2:nth-of-type(3){
            background-image: url('../../assets/Solution/4-3.png');
        }
    }
}
// 业务方案
.SchemeArchitecture {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0 88px 0;
    box-sizing: border-box;
    .title {
        text-align: center;
        font-size: 32px;
        line-height: 32px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0a1e2d;
        margin: 0;
        margin-bottom: 56px;
    }
    .ProductArchitecturecontent {
        width: 1200px;
        // height: 788px;
        margin-top: 50px;
        // padding: 20px;
        box-sizing: border-box;
        // background-color: #fff;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
// 方案优势
.SchemeAdvantages {
    width: 1200px;
    // height: 901px;
    margin: 0 auto;
    padding: 64px 0 78px 0;
    box-sizing: border-box;
    .title {
        text-align: center;
        font-size: 32px;
        font-family: SourceHanSansSC;
        font-weight: 400;
        color: #00acff;
        margin: 0;
        margin-bottom: 70px;
    }
    .contentWrap {
        width: 1200px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .boxWrap {
            width: 590px;
            // height: 163px;
            background: #ffffff;
            box-shadow: 0px 1px 6px 0px rgba(137, 164, 176, 0.4);
            padding: 32px 20px;
            box-sizing: border-box;
            display: flex;
            margin-bottom: 20px;
            margin-right: 20px;
            .leBox {
                margin-right: 18px;
                img {
                    width: 60px;
                    height: 60px;
                }
            }
            .riBox {
                .tit {
                    font-size: 18px;
                    line-height: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: 600;
                    color: #0a1e2d;
                    margin-bottom: 16px;
                }
                .tip {
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #3b4d5b;
                    line-height: 24px;
                }
            }
        }
        .boxWrap:nth-of-type(2) {
            margin-right: 0;
        }
        .boxWrap:nth-of-type(4) {
            margin-right: 0;
        }
        .boxWrap:nth-of-type(6) {
            margin-right: 0;
        }
    }
}
// 落地实践
.LandingPractice {
    width: 1200px;
    // height: 901px;
    margin: 0 auto;
    padding: 64px 0 88px 0;
    box-sizing: border-box;
    .title {
        text-align: center;
        font-size: 32px;
        line-height: 32px;
        font-family: SourceHanSansSC;
        font-weight: 400;
        color: #3b3e41;
        margin: 0;
        margin-bottom: 56px;
    }
    .UseContent {
        display: flex;
        justify-content: space-between;

        .nameWrap {
            height: 400px;
            overflow-y: auto;
            overflow-x: hidden;
            // .Carousel{
            //     display: flex;
            //     width: 245px;
            // }
            .titWrap {
                width: 205px;
                // height: 97px;
                background: #ffffff;
                border-radius: 6px;
                box-sizing: border-box;
                background-color: #fff;
                margin-bottom: 8px;
                color: #3b4d5b;
                display: flex;
                padding: 30px 24px;

                .tipContent {
                    line-height: 18px;
                    font-size: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    margin-right: 16px;
                }
                .line {
                    width: 1px;
                    height: 20px;
                    background: #0a1e2d;
                    margin-right: 16px;
                }
                .tit {
                    font-size: 18px;
                    line-height: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                }
            }
            // .san {
            //     width: 0;
            //     height: 0;
            //     border-top: 10px solid transparent;
            //     border-right: 10px solid #1096fe;
            //     border-bottom: 10px solid transparent;
            //     position: absolute;
            //     left: -10px;
            //     top: 30px;
            // }
            .titWrap:hover  ,.active.titWrap{
                color: #fff;
                cursor: pointer;
                background: linear-gradient(4deg, #1096fe, #a2f0fd);
                opacity: 0.72;
                .line {
                    background: #00acff;
                }
            }
            &::-webkit-scrollbar-track-piece {
                background: #d3dce6;
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(153, 169, 191, 0.506);
                border-radius: 20px;
            }
        }

        .contWrap {
            height: 400px;
            background: #d9eeff;
            display: flex;
            justify-content: space-around;
            position: relative;
            .san {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 30px;
            }
            .san2 {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 150px;
            }
            .san3 {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 270px;
            }
            .san4 {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 400px;
            }
            .leftBox {
                width: 980px;
                height: 400px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
//使用场景
.UsageScenarios,
.UsageScenarios2 {
    width: 1200px;
    // height: 901px;
    margin: 0 auto;
    padding: 64px 0 88px 0;
    box-sizing: border-box;
    .title {
        text-align: center;
        font-size: 32px;
        line-height: 32px;
        font-family: SourceHanSansSC;
        font-weight: 400;
        color: #3b3e41;
        margin: 0;
        margin-bottom: 56px;
    }
    .UseContent {
        display: flex;
        justify-content: space-between;
        .nameWrap {
            height: 524px;
            overflow-y: auto;
            overflow-x: hidden;
            .titWrap {
                width: 438px;
                //   height: 114px;
                padding: 24px 20px;
                box-sizing: border-box;
                background-color: #fff;
                margin-bottom: 8px;
                color: #3b4d5b;
                .tit {
                    font-size: 18px;
                    line-height: 18px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    margin-bottom: 16px;
                }
                .tipContent {
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 20px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden; //溢出内容隐藏
                    text-overflow: ellipsis; //文本溢出部分用省略号表示
                    display: -webkit-box; //特别显示模式
                    -webkit-line-clamp: 2; //行数
                    line-clamp: 2;
                    -webkit-box-orient: vertical; //盒子中内容竖直排列
                    text-align: justify;
                }
            }
            .titWrap:hover ,.active.titWrap{
                color: #fff;
                cursor: pointer;
                background: #00acff;
                .tipContent {
                    overflow: auto;
                    // text-overflow: initial;
                    display: block;
                }
            }
            &::-webkit-scrollbar-track-piece {
                background: #d3dce6;
            }

            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-thumb {
                background: rgba(153, 169, 191, 0.2);
                border-radius: 20px;
            }
        }

        .contWrap {
            height: 524px;
            background: #d9eeff;
            display: flex;
            justify-content: space-around;
            position: relative;
            .san {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 30px;
            }
            .san2 {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 150px;
            }
            .san3 {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 270px;
            }
            .san4 {
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-right: 10px solid #1096fe;
                border-bottom: 10px solid transparent;
                position: absolute;
                left: -10px;
                top: 400px;
            }
            .leftBox {
                width: 747px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
.UsageScenarios2 {
    .nameWrap {
        height: 470px !important;
        .titWrap {
            width: 350px !important;
            padding: 20px !important;
            .tit {
                margin-bottom: 12px !important;
            }
        }
    }
    .contWrap {
        height: 470px !important;
    }
    .leftBox {
        width: 830px !important;
    }
}
