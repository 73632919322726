
//产品架构
.ProductArchitecture {
  width: 1200px;
  margin: 0 auto;
  padding: 64px 0 88px 0;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 32px;
    line-height: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #00acff;
    margin: 0;
    margin-bottom: 56px;
  }
  .ProductArchitecturecontent {
    width: 1200px;
    // height: 512px;
    margin-top: 50px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
//功能特性
.ProductCharacteristics {
  width: 1200px;
  // height: 901px;
  margin: 0 auto;
  padding: 64px 0 112px 0;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #3b3e41;
    margin: 0;
    margin-bottom: 56px;
  }
  .introduce {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .box  ,.box1, .box2 ,.box3{
      width: 596px;
      height: 200px;
      padding: 42px 32px 42px 112px;
      box-sizing: border-box;
      border: 1px solid #d5e0e8;
      background-size: 100% 100%;
      margin-bottom: 26px;
    }
    .box {
      background-image: url(../../assets/ProductService/01.png);
    }
    .box1  {
      background-image: url(../../assets/ProductService/02.png);
    }
    .box2 {
      background-image: url(../../assets/ProductService/03.png);
    }
    .box3  {
      background-image: url(../../assets/ProductService/04.png);
    }
    .tit {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #0a1e2d;
    }
    .tip {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #0a1e2d;
      line-height: 24px;
    }
  }
}
//产品优势
.ProductAdvantages {
  width: 1200px;
  // height: 901px;
  margin: 0 auto;
  padding: 64px 0 78px 0;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 32px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #00acff;
    margin: 0;
    margin-bottom: 70px;
  }
  .contentb {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .boxWrap {
      width: 350px;
      display: flex;
      margin-bottom: 56px;
      margin-right: 42px;
      .leBox {
        margin-right: 18px;
        // width: 48px;
        // height: 48px;
        // background-image: url(../../assets/图二小.png);
        // background-size: 100% 100%;
        img {
          width:60px;
          height: 60px;
        }
      }
      .riBox {
        width: 320px;
        .tit {
          font-size: 18px;
          line-height: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0a1e2d;
          margin-bottom: 16px;
        }
        .tip {
          // text-indent: 2em;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #3b4d5b;
          line-height: 24px;
        }
      }
    }
    .boxWrap :nth-of-type(3) {
      margin-right: 0;
    }
    .boxWrap :nth-of-type(6) {
      margin-right: 0;
    }
  }
}
//使用场景
.UsageScenarios {
  width: 1200px;
  // height: 901px;
  margin: 0 auto;
  padding: 64px 0 88px 0;
  box-sizing: border-box;
  .title {
    text-align: center;
    font-size: 32px;
    line-height: 32px;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #3b3e41;
    margin: 0;
    margin-bottom: 56px;
  }
  .UseContent {
    display: flex;
    justify-content: space-between;
    .nameWrap {
      height: 524px;
      overflow-y: auto;
      overflow-x: hidden;
      .titWrap {
        width: 438px;
        //   height: 114px;
        padding: 24px 20px;
        box-sizing: border-box;
        background-color: #fff;
        margin-bottom: 8px;
        color: #3b4d5b;
        .tit {
          font-size: 18px;
          line-height: 18px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          margin-bottom: 16px;
        }
        .tipContent {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 20px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列
          text-align: justify;
        }
      }
      .titWrap:hover ,.active.titWrap{
        color: #fff;
        cursor: pointer;
        background: #00acff;
        .tipContent {
          overflow: auto;
          // text-overflow: initial;
          display: block;
        }
      }
      &::-webkit-scrollbar-track-piece {
        background: #d3dce6;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(153, 169, 191, 0.2);
        border-radius: 20px;
      }
    }

    .contWrap {
      height: 524px;
      background: #d9eeff;
      display: flex;
      justify-content: space-around;
      position: relative;
      .san {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid #1096fe;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: -10px;
        top: 30px;
      }
      .san2 {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid #1096fe;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: -10px;
        top: 150px;
      }
      .san3 {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid #1096fe;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: -10px;
        top: 270px;
      }
      .san4 {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid #1096fe;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: -10px;
        top: 400px;
      }
      .leftBox {
        width: 747px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
