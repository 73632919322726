.content {
    width: 1200px;
    height: 680px;
    margin: 0 auto;
    padding: 86px 0 0 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .smallWrap {
        width: 384px;
        height: 331px;
        background: #ffffff;
        border-radius: 8px;
        padding: 32px 40px;
        box-sizing: border-box;
        border: 0;
        // background: none;
        position: relative;
        outline: none;
        .titleWrap {
            width: 304px;
            height: 72px;
            border: 1px solid #e6e6e6;
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            color: #2a6eee;
            padding: 14px 32px;
            box-sizing: border-box;
            text-align: center;
        }
        .contentWrap {
            // width: 304px;
            // height: 111px;
            margin-top: 24px;
            font-size: 14px;
            color: #666666;
            line-height: 24px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 5; //行数
            line-clamp: 5;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
            text-align: justify;
            margin-bottom: 40px;
        }
        .btnWrap {
            // width: 80px;
            // margin-top: 50px;
            font-size: 13px;
            // line-height: 13px;
            color: #2E8FE9;
            line-height: 24px;
        }
    }

    .smallWrap::before,
    .smallWrap::after {
        box-sizing: inherit;
        position: absolute;
        content: "";
        border: 2px solid transparent;
        width: 0;
        height: 0;
    }

    .smallWrap::after {
        bottom: 0;
        right: 0;
    }

    .smallWrap::before {
        top: 0;
        left: 0;
        transition: width 0.2s ease-out, height 0.2s ease-out 0.2s;
    }
    .smallWrap:hover {
        box-shadow: 0px 1px 8px 0px rgba(23, 132, 255, 0.24);
    }
    .smallWrap:hover::before,
    .smallWrap:hover::after {
        width: 100%;
        height: 100%;
        color: green;
    }

    .smallWrap:hover::before {
        border-top-color: #4361ee;
        border-right-color: #4361ee;
    }

    .smallWrap:hover::after {
        border-bottom-color: #4361ee;
        border-left-color: #4361ee;
        transition: border-color 0s ease-out 0.4s, width 0.4s ease-out 0.4s, height 0.4s ease-out 0.8s;
    }
    a {
        text-decoration: none;
    }
}

.BJlaboremployment {
    width: 100%;
    .headStyle {
        width: 1200px;
        height: 440px;
        margin: 0 auto;
        .leftBox {
            width: 420px;
            margin-top: 112px;
        }
        .fontBox {
            height: 190px;
        }
        .pStyle {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #243a4a;
        }
    }
    .gotoPage {
        width: 1200px;
        height: 80px;
        border-bottom: 1px solid #e6e6e6;
        margin: 0 auto;
        line-height: 80px;
        font-size: 14px;
        color: #666666;
        a {
            text-decoration: none;
            color: #999999;
        }
    }
    .project {
        width: 1200px;
        height: 136px;
        margin: 0 auto;
        border-bottom: 1px solid #e6e6e6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 32px 0;
        box-sizing: border-box;
        .leftWrap {
            display: flex;
            align-items: center;
            .img {
                width: 40px;
                height: 38px;
                margin-right: 18px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .title {
                font-size: 24px;
                color: #2776d9;
                line-height: 24px;
            }
        }

        .introduce {
            width: 782px;
            .tit {
                font-size: 18px;
                line-height: 18px;
                font-weight: bold;
                color: #333333;
                margin: 0 0 14px 0;
            }
            .tip {
                font-size: 16px;
                color: #999999;
                line-height: 24px;
                text-align: justify;
            }
        }
    }
    .describe{
        width: 1200px;
        margin: 0 auto;
        font-size: 14px;
        color: #666666;
        line-height: 24px;
        margin-top: 46px;
        margin-bottom: 55px;
    }
    .Exhibition{
        width: 938px;
        height: 474px;
        margin: 0 auto;
        margin-bottom: 88px;
        img{
            width: 100%;
            height: 100%;
        }
    }
}
