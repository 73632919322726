.bannerContent {
    width: 1200px;
    height: 440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // outline: 1px solid green

    .leftBox {
        width: 420px;
        margin-top: 0px !important;
    }
    .fontBox {
        height: 190px;
    }
    .pStyle {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #243a4a;
    }
    .rightBox {
        img {
            width: 464px;
            height: 280px;
        }
    }
}
.Box {
    width: 100%;
    height: 64px;
    // background: rgba(255;255;255;0.28);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.04);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    .firstBox {
        background: rgba(255, 255, 255, 0.28);
    }
    .changeBox {
        background: black;
    }

    .headStyle2 {
        width: 1200px;
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        // outline: 1px solid green
        position: relative;
    }
    .leftBox2 {
        display: flex;
        // align-items: center;
    }
    .lineStyle {
        width: 500px;
        height: 64px;
        display: flex;
        list-style: none;
        text-decoration: none;
        // margin:0;
        // padding:0;
        // outline: 1px solid green
    }
    .liStyle {
        // height:60px;

        flex: 1;
        text-align: center;
    }
    .linkStyle {
        height: 62px;
        line-height: 62px;
        text-decoration: none;
        font-size: 15px;
        color: #333333;
    }
    .ImgStyle {
        width: 160px;
        height: 32px;
    }
    .rightBox {
        line-height: 64px;
        display: flex;
    }
    .PhoneStyle {
        width: 16px;
        height: 16px;
    }
    .fontStyle {
        font-size: 14px;
        color: #1096fe;
    }
    .line {
        margin: 0 auto;
        width: 40px;
        height: 4px;
        background: #00acff;
        border-radius: 4px 4px 0px 0px;
    }
    .downBox {
        width: 700px;
        height: 365px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.04);
        position: absolute;
        top: 66px;
        left: 0px;
    }
    .downBox2 {
        width: 210px;
        // height: 260px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.04);
        position: absolute;
        top: 66px;
        left: 230px;
    }
    .ulStyle {
        display: flex;
        padding: 40px;
        box-sizing: border-box;
        margin: 0;
        justify-content: space-between;
        list-style: none;
    }
    .liBox {
        flex: 1;
        text-align: left;
    }
    .title {
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        color: #4d4d4d;
        margin: 0;
        margin-bottom: 8px;
    }
    .tips {
        font-size: 13px;
        line-height: 13px;
        color: #a7b9d2;
        margin: 0;
        margin-bottom: 8px;
    }
    .lineBox {
        width: 204px;
        height: 1px;
        background: #e6e6e6;
        margin-bottom: 24px;
    }
    .lineBox2 {
        width: 130px;
        height: 1px;
        background: #e6e6e6;
        margin-bottom: 24px;
    }
    .nameWrap {
        margin-bottom: 24px;
    }
    .nameWrap:hover {
        .tname {
            color: #1784ff;
            font-weight: bold;
        }
        .tip {
            color: #333333;
        }
    }
    .tname {
        font-size: 13px;
        line-height: 13px;
        color: #4d4d4d;
        margin: 0;
        margin-bottom: 8px;
    }

    .tip {
        font-size: 12px;
        line-height: 12px;
        color: #a7b9d2;
        margin: 0;
    }
    .downImg {
        width: 118px;
        height: 32px;
    }
    .ulStyle2 {
        display: flex;
        padding: 40px;
        box-sizing: border-box;
        margin: 0;
        list-style: none;
    }
    .liBox2 {
        width: 204px;
        margin-right: 24px;
        text-align: left;
    }
    .font {
        margin: 0;
        line-height: 14px;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 24px;
    }
    .spanStyle {
        display: block;
        width: 181px;
        height: 172px;
        line-height: 1.5;
        font-size: 13px;
        color: #333333;
    }
}

.footerBox {
    width: 100%;
    // height: 463px;
    background: #202d3e;
    .headStyle {
        width: 1200px;
        height: 100%;
        margin: 0 auto;
        padding: 64px 0 0 0;
        box-sizing: border-box;
    }
    .topBox {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
    }
    .p {
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        margin-bottom: 40px;
    }
    .tbox {
        width: 408px;
        height: 270px;
        display: flex;
        flex-direction: column;
        // justify-content: 'space-between';
        flex-wrap: wrap;
    }
    .liBox {
        width: 204px;
        margin-bottom: 24px;
        // marginRight:'84px'
    }
    .liBox2 {
        width: 204px;
        margin-bottom: 24px;
    }
    .title {
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        color: rgba(218, 235, 255, 0.56);
        margin: 0;
        margin-bottom: 16px;
    }
    .lineBox {
        width: 120px;
        height: 1px;
        background: #abc4d6;
        margin-bottom: 16px;
    }
    .tname {
        font-size: 13px;
        line-height: 13px;
        color: rgba(218, 235, 255, 0.56);
        margin: 0;
        margin-bottom: 16px;
    }
    .tname:hover {
        color: #1784ff;
    }
    .solvebox {
        width: 204px;
    }
    .title2 {
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        color: rgba(218, 235, 255, 0.56);
        margin: 0;
        //  margin-bottom: '16px'
    }
    .title2:hover {
        color: #1784ff;
    }
    .contactbox {
        width: 260px;
    }
    .cont {
        width: 260px;
        background: #394a60;
        padding: 8px 16px;
        font-size: 14px;
        line-height: 1.5;
        color: rgba(218, 235, 255, 0.56);
        box-sizing: border-box;
    }
    .contentBox {
        margin-bottom: 24px;
    }
    .lineWrap {
        width: 1200px;
        height: 1px;
        background: #394a60;
        border-radius: 1px;
    }
    .bottomBox {
        text-align: center;
        padding: 16px 0;
    }
    .a {
        font-size: 15px;
        line-height: 15px;
        color: #DAEBFF;
    }
    .title3 {
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        color: rgba(218, 235, 255, 0.56);
        margin: 0;
        margin-bottom: 16px;
    }
}
