.BannerStyles {
    width: 1200px;
    height: 716px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin: 0 auto;
    // outline: 1px solid green
    position: relative;
    .leftBox {
        width: 450px;
        // height: 270px;
        // border: 1px solid black;
        position: absolute;
        z-index: 12;
    }
    .fontBox {
        width: 100%;
        // height: 190px;
        margin-bottom: 70px;
    }
    .pStyle {
        font-size: 60px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #00053b;
        margin: 0;
        margin-bottom: 20px;
        margin-top: 246px;
        letter-spacing: 0.3em;
        font-weight: bold;
    }
    .spanStyle {
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #252e39;
        line-height: 1.5;
    }
    .buttonBox {
        width: 160px;
        height: 40px;
        line-height: 40px;
        background: #ffffff;
        border-radius: 20px;
        // text-align: center;
        cursor: pointer;
        position: relative;
        display: flex;
        .word {
            width: 100%;
            font-size: 18px;
            color: #666666;
            // font-weight: bold;
            border: 0;
            border-radius: 50px;
            text-transform: uppercase;
            background: linear-gradient(to right, #00ACFF 50%, #fff 50%);
            background-size: 200% 100%;
            background-position: right bottom;
            transition: all 1s ease;
        }
        .ant-space-item {
            width: 40px;
            height: 40px;
            text-align: center;
            border-radius: 50%;
            background: #00ACFF;
            margin-right: 16px;
            color: #fff;
        }
        .word:hover {
            background-position: left bottom;
            color: #fff;
        }
    }

    .rightBox {
        width: 852px;
        height: 716px;
        // background: rgba(255,255,255,0.21);
        // border: 1px solid #FFFFFF;
        border-radius: 27px;
        position: absolute;
        top: 0;
        right: -118px;
        z-index: 0;
        img {
            width: 100%;
            height: 100%;
        }
    }
}
//产品介绍
.ContentStyles {
    width: 1200px;
    height: 1000px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin: 0 auto;
    // outline: 1px solid green;
    padding: 64px 0 88px 0;
    box-sizing: border-box;
    .p {
        text-align: center;
        font-size: 32px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #3b3e41;
        margin: 0;
        margin-bottom: 64px;
    }
    .topBox {
        height: 326px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 88px;
    }
    .topBox2 {
        height: 326px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .img {
        width: 532px;
        height: 326px;
        background: #d8efff;
        border-radius: 10px;
        .backImage {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    .font {
        width: 588px;
    }
    .title {
        font-size: 20px;
        font-weight: 600;
        color: #333333;
        margin: 0;
        margin-bottom: 16px;
        cursor: pointer;
    }
    .title2 {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
        margin: 0;
        margin-bottom: 20px;
    }
    .span {
        display: block;
        font-size: 14px;
        line-height: 2;
        color: #333333;
        margin-bottom: 30px;
    }
    .btn {
        width: 588px;
        display: flex;
        flex-wrap: wrap;
    }

    .sWrap {
        padding: 8px 32px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0px 1px 8px 0px rgba(23, 132, 255, 0.24);
        border-radius: 20px;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        color: #00ACFF;
        margin-right: 24px;
    }
    .sWrap:nth-of-type(1) {
        margin-bottom: 8px;
    }
    .sWrap:nth-of-type(2) {
        margin-bottom: 8px;
    }
}
//解决方案
.ContentStyles2 {
    width: 1200px;
    height: 692px;
    // display: flex;
    // justify-content: space-between;
    // jualign-items: center;
    margin: 0 auto;
    // outline: 1px solid green;
    padding: 64px 0 88px 0;
    box-sizing: border-box;
    position: relative;

    .img{
        width: 648px;
        height: 260px;
        position: absolute;
        bottom: 0;
        right: -324px;
        img{
            width: 648px;
            height: 260px;
        }
    }
    .p {
        text-align: center;
        font-size: 32px;
        font-family: SourceHanSansSC;
        font-weight: 600;
        color: #3b3e41;
        margin: 0;
        margin-bottom: 80px;
    }
    .tBox {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
      
    }
    .sBox {
        width: 386px;
        height: 200px;
        // background: #d8efff;
        border-radius: 10px;
        margin-bottom: 24px;
        margin-right: 12px;
       
        background-image: 100% 100%;
        box-shadow: 0px 2px 8px 0px rgba(35, 171, 235, 0.24);
        border: 2px solid #ffffff;
        position: relative;
        overflow: hidden;
        .fontBox {
            // padding: 72px 0px 0px 32px;
            padding: 64px 0px 0px 32px;
            box-sizing: border-box;
            width: 382px;
            height: 196px;
            border-radius: 10px;
            transition: all 0.5s 0.1s;
            position: absolute;
            top: 0;
            left: 0;
            span {
                display: inline-block;
                padding: 6px 10px;
                box-sizing: border-box;
                font-size: 14px;
                line-height: 14px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #00053b;
                background: rgba(255, 255, 255, 0.36);
                border: 1px solid #e2ebf2;
                border-radius: 2px;
                margin-right: 10px;
                background: #fff;
            }
            .title {
                display: block;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #00053b;
                margin-bottom: 24px;
            }
        }
        .behindBox {
            width: 382px;
            height: 196px;
            border-radius: 10px;
            // background: #00ACFF;
            // backdrop-filter: blur(12px);
            // padding: 44px 24px;
            // box-sizing: border-box;
            position: absolute;
            left: 0;
            top: 210px;
            transition: all 0.5s;

            .bg {
                width: 100%;
                height: 100%;
                position: relative;
                // background:  #00ACFF;

                // background: inherit;
                .font {
                    background: inherit;
                    box-sizing: border-box;
                    padding: 44px 24px;
                    border-radius: 10px;
                    overflow: hidden;
                    z-index: 2;
                    position: absolute;
                    .title {
                        display: block;
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #00053b;
                        margin-bottom: 24px;
                    }
                    .contentWrap {
                        margin-top: 24px;
                        font-size: 14px;
                        color: #00053b;
                        line-height: 24px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden; //溢出内容隐藏
                        text-overflow: ellipsis; //文本溢出部分用省略号表示
                        display: -webkit-box; //特别显示模式
                        -webkit-line-clamp: 3; //行数
                        line-clamp: 3;
                        -webkit-box-orient: vertical; //盒子中内容竖直排列
                        text-align: justify;
                    }
                }
            }
            .bg::before {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                // background: url(../../assets/csaeBack.jpg) ;
                // background-image: 100% 100%;
                background-color: rgba(255, 255, 255, 0.3);
                filter: blur(10px);
                z-index: 1;
            }
        }
    }
    .sBox:hover {
        border: 2px solid #00ACFF;

        .fontBox {
            // top: -200px;
            opacity: 0;
        }
        .behindBox {
            top: 0;
        }
    }
    .sBox:nth-of-type(1){
        background: url(../../assets/home/5.png);
    }
    .sBox:nth-of-type(2){
        background: url(../../assets/home/4.png);
    }
    .sBox:nth-of-type(3) {
        margin-right: 0px;
        background: url(../../assets/home/3.png);
    }
    .sBox:nth-of-type(4){
        background: url(../../assets/home/2.png);
    }
    .sBox:nth-of-type(5){
        background: url(../../assets/home/1.png);
    }
}
//为啥选择我们
.ContentStyles3 {
    width: 1200px;
    height: 628px;
    margin: 0 auto;
    // outline: 1px solid green;
    padding-top: 64px;
    box-sizing: border-box;
    .p {
        text-align: center;
        font-size: 32px;
        line-height: 32px;
        font-family: SourceHanSansSC;
        font-weight: 600;
        color: #3b3e41;
        margin: 0;
        margin-bottom: 88px;
    }
    .tBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .lBox {
        width: 379px;
        // height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .pbox {
        margin: 0;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        color: #00ACFF;
        margin-bottom: 14px;
    }
    .spanbox {
        font-size: 14px;
        color: #4d4d4d;
        line-height: 21px;
    }
    .imgBox {
        width: 350px;
        // height: 252px;
        // background: #d8efff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            // height: 100%;
        }
        // button {
        //     border: 0;
        //     background: none;
        //     text-transform: uppercase;
        //     color: #4361ee;
        //     font-weight: bold;
        //     position: relative;
        //     outline: none;
        //     padding: 10px 20px;
        //     box-sizing: border-box;
        // }

        // button::before,
        // button::after {
        //     box-sizing: inherit;
        //     position: absolute;
        //     content: "";
        //     border: 2px solid transparent;
        //     width: 0;
        //     height: 0;
        // }

        // button::after {
        //     bottom: 0;
        //     right: 0;
        // }

        // button::before {
        //     top: 0;
        //     left: 0;
        // }

        // button:hover::before,
        // button:hover::after {
        //     width: 100%;
        //     height: 100%;
        //     color: green;
        // }

        // button:hover::before {
        //     border-top-color: #4361ee;
        //     border-right-color: #4361ee;
        //     transition: width 0.3s ease-out, height 0.3s ease-out 0.3s;
        // }

        // button:hover::after {
        //     border-bottom-color: #4361ee;
        //     border-left-color: #4361ee;
        //     transition: border-color 0s ease-out 0.6s, width 0.3s ease-out 0.6s, height 0.3s ease-out 1s;
        // }
    }

    .pbox2 {
        margin: 0;
        font-size: 18px;
        line-height: 18px;
        font-weight: 600;
        color: #00ACFF;
        margin-bottom: 14px;
        text-align: right;
    }
    .spanbox2 {
        display: block;
        font-size: 14px;
        color: #4d4d4d;
        line-height: 21px;
        text-align: right;
    }
}
//用户案例
.ContentStyles4 {
    width: 1200px;
    height: 660px;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin: 0 auto;
    // outline: 1px solid green;
    padding-top: 88px;
    box-sizing: border-box;
    .p {
        text-align: center;
        font-size: 32px;
        font-family: SourceHanSansSC;
        font-weight: 600;
        color: #3b3e41;
        margin: 0;
        margin-bottom: 40px;
    }
    .tBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .lBox {
        width: 378px;
    }
    .pBox {
        font-size: 20px;
        font-weight: 600;
        color: #4d4d4d;
        margin: 0;
        margin-bottom: 40px;
    }
    .spanBox {
        display: block;
        font-size: 14px;
        color: #808080;
        margin-bottom: 32px;
        line-height: 2;
    }
    .btn {
        width: 136px;
        height: 40px;
        background: rgba(132, 180, 252, 0.26);
        border-radius: 20px;
        font-size: 15px;
        color: #00ACFF;
        text-align: center;
        line-height: 40px;
        font-weight: 600;
    }
    .btn:hover {
        color: #27446d;
        font-weight: 600;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.04);
    }
    .rBox {
        width: 791px;
        height: 420px;
    }
    .img {
        width: 100%;
        height: 100%;
    }
}
//新闻动态
.ContentStyles5 {
    width: 1200px;
    height: 850px;
    margin: 0 auto;
    // outline: 1px solid green;
    padding-top: 96px;
    box-sizing: border-box;
    .p {
        text-align: center;
        font-size: 36px;
        font-family: SourceHanSansSC;
        font-weight: 600;
        color: #3b3e41;
        margin: 0;
        margin-bottom: 88px;
    }
    .tBox {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
    }
    .sBox {
        width: 588px;
        height: 144px;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        box-shadow: 0px 1px 6px 0px rgba(115, 163, 229, 0.42);
        border-radius: 8px;
        padding: 32px 24px;
        box-sizing: border-box;
        margin-bottom: 24px;
    }
    .wrap {
        width: 28px;
        height: 28px;
        background: #00acff;
        border-radius: 4px;
        font-size: 21px;
        font-weight: 600;
        color: #ffffff;
        text-align: center;
    }
    .top {
        width: 489px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        align-items: center;
    }
    .title {
        font-size: 18px;
        font-weight: 600;
        color: #505050;
    }
    .time {
        font-size: 14px;
        color: #797979;
    }
    .tip {
        font-size: 14px;
        color: #adadad;
    }
    .btn {
        width: 160px;
        height: 40px;
        line-height: 40px;
        background: #ffffff;
        border: 1px solid #00acff;
        border-radius: 8px;
        font-size: 15px;
        color: #00acff;
        margin: 0 auto;
        text-align: center;
    }
}
