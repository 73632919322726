.card-carousels {
    width: 1200px;
    height: 467px;
    background: #ffffff;
    margin: 0 auto;
    // padding: 64px 60px;
    position: relative;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    div {
        box-sizing: border-box;
    }
    .wrapper {
        width: 600px;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        .inner {
            width: 200px;
            height: 286px;
            // border: 4px solid green;
            position: relative;
            .swiper {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                min-width: 100%;
                display: flex;
                flex-wrap: nowrap;
                transition: all 0.5s;

                .swiper-item {
                    // padding: 0 20px;
                    // border:1px solid yellowgreen;
                    width: 200px;
                    transition: all 0.5s;
                    transform: scale(0.8);
                    &.current {
                        transform: scale(1);
                    }
                    .pic {
                        height: 100%;
                        img {
                            border: 2px solid #5d5d5d;
                            width: 196px;
                            height: 282px;
                        }
                    }
                }
                .swiper-item:nth-of-type(7) {
                    .pic {
                        height: 286px;
                        img {
                            width: 396px;
                            height: 282px;
                        }
                    }
                }
            }
            .swiper2,
            .swiper2 .swiper-item {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                font-size: 50px;
                text-align: center;
                line-height: 150px;
            }
            .swiper2 .swiper-item {
                transition: transform 0.5s;
                border: 20px solid #ccc;
                color: black;
                border-radius: 20px;
                background: white;
            }
        }
    }
    .btn-prev,
    .btn-next {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(0, 172, 255, 0.16);
        position: absolute;
        z-index: 9;
        cursor: pointer;
        text-align: center;
        color: #00acff;
        font-size: 24px;
        font-weight: 600;
    }
    .btn-prev {
        left: 60px;
    }
    .btn-next {
        right: 60px;
    }
}
