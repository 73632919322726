.EnterpriseProfile {
    width: 100%;

    .introdece {
        // width: 1200px;
        margin: 0 auto;
        // margin-bottom: 50px;
        padding: 50px 0;
        box-sizing: border-box;

        .title {
            font-size: 32px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #00acff;
            text-align: center;
            margin-bottom: 56px;
        }
        .botContWrap {
            width: 100%;
            height: 425px;
            position: relative;
        }
        .wrap {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: -1;
            .boxWrap {
                width: 50%;
                height: 425px;
            }
        }
        .contWrap {
            width: 1200px;
            height: 425px;
            margin: 0 auto;
            display: flex;
            position: relative;
            img {
                width: 587px;
                height: 321px;
                position: absolute;
                top: 66px;
                right: 0;
            }
            .words {
                padding: 56px 0 0 0;
                box-sizing: border-box;
                background-color: #f2f5fa;
                width: 716px;
                .line {
                    width: 50px;
                    height: 3px;
                    background: #00acff;
                    margin-top: 12px;
                    margin-bottom: 24px;
                }
                .titWords {
                    font-weight: bolder;
                    color: rgb(71, 70, 70);
                    overflow: hidden;
                    font-size: 24px;
                    line-height: 24px;
                    margin: 0;
                }
                .tipWords {
                    width: 509px;
                    height: 206px;
                    font-size: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    line-height: 24px;
                    color: #6f6f6f;
                    text-align: justify;
                    text-indent: 2em;
                }
            }
        }
    }
}
.contentStyle {
    height: 420px;
    margin: 0 auto;
    display: flex;
    display: inline-flex !important;
    justify-content: space-evenly;
    // background: #1A2D48;
    img {
        width: 300px;
        height: 420px;
        display: block;
    }
}
.contentStyle2 {
    height: 420px;
    // background: #1A2D48;
    padding-top: 10px;
    img {
        width: 560px;
        height: 400px;
        margin: 0 auto;
    }
}
.leftButton,
.rightButton {
    border: none;
    height: 36px;
    width: 36px;
    transition: 1s;
    border-radius: 50% !important;
    background-color: #001529;
    color: #ff0000;
    position: absolute !important;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    opacity: 0.3;
}

.leftButton:hover,
.rightButton:hover {
    opacity: 1;
}
