.ContactUs {
    width: 100%;
    position: relative;
    .centerWrap {
        width: 100%;
        position: absolute;
        top: 405px;
        left: 0;
        .totalWrap {
            width: 1200px;
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
            justify-content: space-evenly;
            .smallWrap {
                width: 560px;
                height: 140px;
                background-color: #fff;
                padding: 42px 50px;
                box-sizing: border-box;
                border: 0;
                position: relative;
                outline: none;
                box-shadow: 0px 1px 10px 0px rgba(0, 172, 255, 0.1);
                border-radius: 8px;
                display: flex;
                align-items: center;
                .leftBox {
                    width: 72px;
                    height: 72px;
                    margin-right: 24px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .rightBox {
                    .titleWrap {
                        font-size: 30px;
                        line-height: 30px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #0a1e2d;
                        margin-bottom: 20px;
                    }
                    .contentWrap {
                        font-size: 16px;
                        line-height: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #0a1e2d;
                    }
                }
            }
        }

        // .smallWrap::before,
        // .smallWrap::after {
        //     box-sizing: inherit;
        //     position: absolute;
        //     content: "";
        //     border: 2px solid transparent;
        //     width: 0;
        //     height: 0;
        // }

        // .smallWrap::after {
        //     bottom: 0;
        //     right: 0;
        // }

        // .smallWrap::before {
        //     top: 0;
        //     left: 0;
        //     transition: width 0.2s ease-out, height 0.2s ease-out 0.2s;
        // }
        // .smallWrap:hover {
        //     box-shadow: 0px 1px 8px 0px rgba(23, 132, 255, 0.24);
        // }
        // .smallWrap:hover::before,
        // .smallWrap:hover::after {
        //     width: 100%;
        //     height: 100%;
        //     color: green;
        // }

        // .smallWrap:hover::before {
        //     border-top-color: #4361ee;
        //     border-right-color: #4361ee;
        // }

        // .smallWrap:hover::after {
        //     border-bottom-color: #4361ee;
        //     border-left-color: #4361ee;
        //     transition: border-color 0s ease-out 0.4s, width 0.4s ease-out 0.4s, height 0.4s ease-out 0.8s;
        // }
    }
    .address {
        width: 1200px;
        height: 583px;
        margin: 140px auto 96px;
        box-sizing: border-box;
        background-image: url("../../assets/Contact//address.png");

        .words {
            width: 1200px;
            height: 583px;
            background: linear-gradient(90deg, #f0f5fa 0%, rgba(240, 245, 250, 0) 100%);
            padding: 30px 40px;
            box-sizing: border-box;
            display: flex;
            padding-left: 50px;
            box-sizing: border-box;
            img {
                width: 16px;
                height: 20px;
            }
            .rightWrap {
                margin-left: 10px;
                .tit {
                    font-size: 24px;
                    line-height: 24px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #00acff;
                    margin-top: 244px;
                    margin-bottom: 24px;
                }
                .nameWrap {
                    display: flex;
                    align-items: center;
                    margin-bottom: 24px;
                    .img {
                        width: 36px;
                        height: 36px;
                        margin-right: 8px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .name {
                        font-size: 18px;
                        line-height: 18px;
                    }
                }
                .name2,.name3 {
                    font-size: 14px;
                    line-height: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #0a1e2d;
                }
                .name2{
                    margin-bottom: 16px;
                }
            }
        }
    }
}
