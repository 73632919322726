.internet {
  width: 100%;
  .framework {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: block;
      // margin-top: 15px;
      line-height: 28px;
      font-size: 16px;
      color: #6f6f6f;
      text-align: justify;
      text-indent: 2em;
    }
    .contentWrap {
      width: 1200px;
      height: 462px;
      // background: #d9eeff;
      margin-top: 50px;
      // border: 5px dashed rgb(207, 207, 207);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .characteristic {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .box {
        width: 600px;
        padding: 30px;
        box-sizing: border-box;
        padding-top: 17px;
        opacity: 0.8;
        background: #eaf1fb;
        border: 1px solid #d5e0e8;
        h1 {
          height: 64px;
          font-size: 71px;
          font-weight: 700;
          color: #e0e1e2;
        }
        .tit {
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
          margin-top: -9px;
        }
        .tip {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
  }
  .advantage {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contentb {
      width: 1200px;
      // height: 612px;
      // background: #D9EEFF;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .boxWrap {
        width: 380px;
        height: 250px;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        background: #f5f9fc;
        padding: 20px;
        margin-right: 10px;
        border-radius: 20px;
        .tit {
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2d95ce;
          margin: 24px 0 12px 20px;
          line-height: 28px;
        }
        .tip {
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #393b3e;
          line-height: 24px;
        }
      }
    }
  }
  .scene {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .nameWrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tit {
        font-size: 18px;
      }
    }
    .contWrap {
      width: 100%;
      height: 550px;
      background: #d9eeff;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      position: relative;
      .san {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 132px;
        top: -30px;
      }
      .san2 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 436px;
        top: -30px;
      }
      .san3 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 732px;
        top: -30px;
      }
      .san4 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 1034px;
        top: -30px;
      }
      .leftBox {
        flex: 2;
        // background: blueviolet;
      }
      .rightBox {
        flex: 1;
        padding: 88px 50px 0 50px;
        box-sizing: border-box;
        border-left: 1px solid #b8b9ba;
        h3 {
          font-size: 18px;
          color: #2d95ce;
          margin-bottom: 30px;
        }
        .cont {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          text-indent: 2em;
        }
      }
    }
  }
}
.campus {
  width: 100%;
  .framework {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: block;
      // margin-top: 15px;
      line-height: 28px;
      font-size: 16px;
      color: #6f6f6f;
      text-align: justify;
      text-indent: 2em;
    }
    .contentWrap {
      width: 1200px;
      height: 462px;
      // background: #d9eeff;
      margin-top: 50px;
      // border: 5px dashed rgb(207, 207, 207);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .characteristic {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .box {
        width: 600px;
        padding: 30px;
        box-sizing: border-box;
        padding-top: 17px;
        opacity: 0.8;
        background: #eaf1fb;
        border: 1px solid #d5e0e8;
        h1 {
          height: 64px;
          font-size: 71px;
          font-weight: 700;
          color: #e0e1e2;
        }
        .tit {
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
          margin-top: -9px;
        }
        .tip {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
  }
  .advantage {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contentb {
      width: 1200px;
      // height: 612px;
      // background: #D9EEFF;
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      .boxWrap {
        width: 380px;
        height: 250px;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        //   background: #f5f9fc;
        background: #fff;
        padding: 30px;
        margin-right: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        .tit {
          // height: 28px;
          font-size: 20px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2d95ce;
          margin: 24px 0 12px 0px;
          line-height: 28px;
        }
        .tip {
          text-align: justify;
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #393b3e;
          line-height: 24px;
        }
      }
    }
  }
  .scene {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .nameWrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tit {
        font-size: 18px;
      }
    }
    .contWrap {
      width: 100%;
      height: 550px;
      background: #d9eeff;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      position: relative;
      .san {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 132px;
        top: -30px;
      }
      .san2 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 436px;
        top: -30px;
      }
      .san3 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 732px;
        top: -30px;
      }
      .san4 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 1034px;
        top: -30px;
      }
      .leftBox {
        flex: 2;
        // background: blueviolet;
      }
      .rightBox {
        flex: 1;
        padding: 88px 50px 0 50px;
        box-sizing: border-box;
        border-left: 1px solid #b8b9ba;
        h3 {
          font-size: 18px;
          color: #2d95ce;
          margin-bottom: 30px;
        }
        .cont {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          text-indent: 2em;
        }
      }
    }
  }
  .UsageScenarios {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contenta {
      width: 1200px;
      height: 612px;
      background: #d9eeff;
      padding: 30px;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
      .vidio {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
.governmentAffairs {
  width: 100%;
  .framework {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: block;
      // margin-top: 15px;
      line-height: 28px;
      font-size: 16px;
      color: #6f6f6f;
      text-align: justify;
      text-indent: 2em;
    }
    .contentWrap {
      width: 1200px;
      height: 462px;
      // background: #d9eeff;
      margin-top: 50px;
      // border: 5px dashed rgb(207, 207, 207);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .characteristic {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .box {
        width: 600px;
        padding: 30px;
        box-sizing: border-box;
        padding-top: 17px;
        opacity: 0.8;
        background: #eaf1fb;
        border: 1px solid #d5e0e8;
        h1 {
          height: 64px;
          font-size: 71px;
          font-weight: 700;
          color: #e0e1e2;
        }
        .tit {
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
          margin-top: -9px;
        }
        .tip {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
  }
  .advantage {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contentb {
      width: 1200px;
      // height: 612px;
      // background: #D9EEFF;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .boxWrap {
        width: 380px;
        height: 250px;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        background: #f5f9fc;
        padding: 20px;
        margin-right: 10px;
        border-radius: 20px;
        .tit {
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgb(45, 149, 206);
          margin: 24px 0 12px 20px;
          line-height: 28px;
        }
        .tip {
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #393b3e;
          line-height: 24px;
        }
      }
    }
  }
  .scene {
    width: 1200px;
    //   height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .nameWrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tit {
        font-size: 18px;
      }
    }
    .contWrap {
      width: 100%;
      height: 612px;
      // background: #d9eeff;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      position: relative;
      .san {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 132px;
        top: -30px;
      }
      .san2 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 436px;
        top: -30px;
      }
      .san3 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 732px;
        top: -30px;
      }
      .san4 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 1034px;
        top: -30px;
      }
      .contentWrap {
        width: 1200px;
        height: 612px;
        // background: #d9eeff;
        // margin-top: 50px;
        // border: 5px dashed rgb(207, 207, 207);
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .UsageScenarios {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contenta {
      width: 1200px;
      height: 612px;
      background: #d9eeff;
      padding: 30px;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
      .vidio {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
.energy {
  width: 100%;
  .framework {
    width: 1200px;
    //   height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contentWrap {
      width: 1200px;
      height: 612px;
      // background: #d9eeff;
      margin-top: 50px;
      // border: 5px dashed rgb(207, 207, 207);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .contentWrap2 {
      width: 1200px;
      height: 612px;
      // background: #d9eeff;
      margin-top: 50px;
      // border: 5px dashed rgb(207, 207, 207);
      padding: 0 60px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .framework2 {
    width: 1200px;
    //   height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }

    .contentWrap2 {
      width: 1200px;
      //   height: 612px;
      // background: #d9eeff;
      margin-top: 50px;
      // border: 5px dashed rgb(207, 207, 207);
      padding: 0 60px;
      box-sizing: border-box;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .painWrap {
    width: 1200px;
    // height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .painBox {
        width: 380px;
        height: 250px;
        box-shadow: 0 3px 10px 1px #b1b7d066;
        border-radius: 0 0 0 0;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        background: #fff;
        padding: 30px;
        box-sizing: border-box;
        h3{
          font-weight: 600;
          text-align: center;
        }
        .fontWrap{
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          color: #393b3e;
          line-height: 24px;
        }
      }
    }
  }
  .characteristic {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .box {
        width: 600px;
        padding: 30px;
        box-sizing: border-box;
        padding-top: 17px;
        opacity: 0.8;
        background: #eaf1fb;
        border: 1px solid #d5e0e8;
        h1 {
          height: 64px;
          font-size: 71px;
          font-weight: 700;
          color: #e0e1e2;
        }
        .tit {
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
          margin-top: -9px;
        }
        .tip {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
  }
  .advantage {
    width: 1200px;
    //   height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    h3 {
      text-align: center;
      margin-bottom: 30px;
    }
    .contentb {
      width: 1200px;
      // height: 612px;
      // background: #D9EEFF;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .boxWrap {
        width: 380px;
        height: 300px;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        background: #f5f9fc;
        padding: 20px;
        margin-right: 10px;
        border-radius: 20px;
        box-sizing: border-box;
        .tit {
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2d95ce;
          margin: 24px 0 12px 0px;
          line-height: 28px;
        }
        .tip {
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #393b3e;
          line-height: 24px;
        }
      }
    }
  }
  .scene {
    width: 1200px;
    //   height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .nameWrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tit {
        font-size: 18px;
      }
    }
    .contWrap {
      width: 100%;
      // height: 550px;
      background: #fff;
      margin-top: 30px;
      position: relative;
      .san {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 84px;
        top: -30px;
      }
      .san2 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 284px;
        top: -30px;
      }
      .san3 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 484px;
        top: -30px;
      }
      .san4 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 688px;
        top: -30px;
      }
      .san5 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 888px;
        top: -30px;
      }
      .san6 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #999;
        position: absolute;
        left: 1088px;
        top: -30px;
      }
      .contentWrap {
        width: 1200px;
        height: 612px;
        // background: #d9eeff;
        // margin-top: 50px;
        .fontWrap{
          height: 50px;
          line-height: 50px;
          font-weight: 600;
          font-size: 26px;
          text-align: center;
        }
        img {
          width: 100%;
          height: 562px;
          // border: 5px dashed rgb(207, 207, 207);
        }
      }
    }
  }
}
.community {
  width: 100%;
  .framework {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contentWrap {
      width: 1200px;
      height: 612px;
      // background: #d9eeff;
      margin-top: 50px;
      // border: 5px dashed rgb(207, 207, 207);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .painWrap {
    width: 1200px;
    // height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .painBox {
        width: 380px;
        height: 200px;
        box-shadow: 0 3px 10px 1px #b1b7d066;
        border-radius: 0 0 0 0;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        color: #393b3e;
        line-height: 24px;
        background: #fff;
        padding: 30px;
        box-sizing: border-box;
        text-indent: 2em;
      }
    }
  }
  .characteristic {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .introduce2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .box {
        width: 600px;
        padding: 30px;
        box-sizing: border-box;
        padding-top: 17px;
        opacity: 0.8;
        background: #eaf1fb;
        border: 1px solid #d5e0e8;
        h1 {
          height: 64px;
          font-size: 71px;
          font-weight: 700;
          color: #e0e1e2;
        }
        .tit {
          font-size: 18px;
          font-weight: 500;
          color: #1a1a1a;
          margin-top: -9px;
        }
        .tip {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          margin-top: 20px;
        }
      }
    }
  }
  .advantage {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contentb {
      width: 1200px;
      // height: 612px;
      // background: #D9EEFF;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .boxWrap {
        width: 380px;
        height: 250px;
        border: 1px solid #ececec;
        margin-bottom: 20px;
        background: #f5f9fc;
        padding: 20px;
        margin-right: 10px;
        border-radius: 20px;
        .tit {
          height: 28px;
          font-size: 20px;
          font-family: PingFang SC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2d95ce;
          margin: 24px 0 12px 20px;
          line-height: 28px;
        }
        .tip {
          text-indent: 2em;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #393b3e;
          line-height: 24px;
        }
      }
    }
  }
  .scene {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .nameWrap {
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tit {
        font-size: 18px;
      }
    }
    .contWrap {
      width: 100%;
      height: 550px;
      background: #d9eeff;
      margin-top: 30px;
      display: flex;
      justify-content: space-around;
      position: relative;
      .san {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 132px;
        top: -30px;
      }
      .san2 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 436px;
        top: -30px;
      }
      .san3 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 732px;
        top: -30px;
      }
      .san4 {
        width: 0;
        height: 0;
        border: 15px solid;
        border-color: transparent transparent #d9eeff;
        position: absolute;
        left: 1034px;
        top: -30px;
      }
      .leftBox {
        flex: 2;
        // background: blueviolet;
      }
      .rightBox {
        flex: 1;
        padding: 88px 50px 0 50px;
        box-sizing: border-box;
        border-left: 1px solid #b8b9ba;
        h3 {
          font-size: 18px;
          color: #2d95ce;
          margin-bottom: 30px;
        }
        .cont {
          font-size: 14px;
          color: #393b3e;
          line-height: 24px;
          text-indent: 2em;
        }
      }
    }
  }
  .UsageScenarios {
    width: 1200px;
    height: 901px;
    margin: 0 auto;
    padding: 88px 0;
    box-sizing: border-box;
    .title {
      text-align: center;
      font-size: 36px;
      font-family: SourceHanSansSC;
      font-weight: 600;
      color: #3b3e41;
      margin: 0;
      margin-bottom: 56px;
    }
    .contenta {
      width: 1200px;
      height: 612px;
      background: #d9eeff;
      // padding: 30px;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
      }
      .vidio {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
